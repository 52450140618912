import React from 'react';
import styled from "styled-components";
import NotFound from '../not-found/NotFound';
import { useTranslation } from 'react-i18next';

const Notifications = () => {

    const {t} = useTranslation();

  return (
    <NotificationsMain className='campaigns-user-joined-main'>
      <CardContainer>
        <NotFound text={`${t("searchResults")} ${t("notFound")}`} />
      </CardContainer>
    </NotificationsMain>
  )
}

export default Notifications

export const NotificationsMain = styled.main`
  background-color: #f8f8f8;
  padding: 80px 0;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  max-width: 1200px;
  margin: 0 auto;
`;