import React, { useState } from "react";
import { IoSettingsOutline, IoNotificationsOutline, IoDocumentTextOutline } from "react-icons/io5";
import { IoMdHelp } from "react-icons/io";
import { AiOutlineHeart, AiFillCaretDown } from "react-icons/ai";
// import { FaUserCircle } from "react-icons/fa"
import { GrHelp } from "react-icons/gr";
import { BiLogOut } from "react-icons/bi";
import { TbCertificate } from "react-icons/tb";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { logout, selectIsAuthenticated } from "../../redux/features/auth/authSlice";
import { Button, Menu, Text } from '@mantine/core';
import { MdEdit } from "react-icons/md";
import Avatar from 'react-avatar-edit';
import { useDisclosure } from '@mantine/hooks';
import { Dialog } from '@mantine/core';
import { postUserPicture } from "../../services/identity/postUserPictureService";
import CurrencyFormat from "../languages-currency/CurrencyFormat";
import { RiHandCoinLine } from "react-icons/ri";
import { Tooltip } from "../../components";
import RemoveTurkishChars from "../../logic/RemoveTurkishChars";
import { getStartLanguage } from "../../logic/getStartLanguage";
import coin from "../../assets/coins-renkli.webp";

const User = ({userPictureData, userPoint}) => {

  const [src, setSrc] = useState(null);
  const [picture, setPicture] = useState(null);
  // const [pictureMessage, setPictureMessage] = useState();
  const [opened, { toggle, close }] = useDisclosure(false);

  const dispatch = useDispatch();
  const userData = useSelector(state => state.user.user?.data);

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { t } = useTranslation();
  const startLanguage = getStartLanguage();

  if (!isAuthenticated) {
    return null;
  }

  const handleLogout = () => {
    dispatch(logout());
  }

  const newsFromUs = t("newsFromUs"); 
  const formattedNewsFromUs = RemoveTurkishChars({ text: newsFromUs }).replace(/ /g, "").toLowerCase();

  const menuItems = [
    {
      icon: <IoSettingsOutline size={20}/>,
      text: t("myAccount"),
      link: `/${startLanguage}/${RemoveTurkishChars({ text: t("accountSettings") })}`,
    },
    {
      icon: <IoNotificationsOutline size={20}/>,
      text: t("notifications"),
      link: `/${startLanguage}/${RemoveTurkishChars({ text: t("notifications") })}`,
    },
    {
      icon: <AiOutlineHeart size={20}/>,
      text: t("myJoinedCampaigns"),
      link: `/${startLanguage}/${RemoveTurkishChars({ text: t("myJoinedCampaigns") })}`,
    },
    {
      icon: <TbCertificate size={20}/>,
      text: t("myCertificates"),
      link: `/${startLanguage}/${RemoveTurkishChars({ text: t("myCertificates") })}`,
    },
    {
      icon: <MdOutlineCheckCircleOutline size={20}/>,
      text: t("favouriteTeams"),
      link: `/${startLanguage}/${RemoveTurkishChars({ text: t("favouriteTeams") })}`,
    },
    {
      icon: <IoDocumentTextOutline size={20}/>,
      text: t("newsFromUs"),
      link: `/${startLanguage}/${formattedNewsFromUs}`,
    },
    // {
    //   icon: <IoMdHelp size={20} />,
    //   text: t("help"),
    //   link: `/${startLanguage}/${RemoveTurkishChars({ text: t("help") })}`,
    //   submenu: [
    //     {
    //       icon: <GrHelp size={20} />,
    //       text: "Second Help Item",
    //       link: "/second-help",
    //     },
    //     // Diğer alt menü öğeleri eklenebilir
    //   ],
    // },
    {
      icon: <BiLogOut size={20}/>,
      text: t("logout"),
      onClick: handleLogout,
    },
  ];

  
  const onClose = () => {
    setPicture(null);
  };
  
  const onCrop = view => {
    setPicture(view);
  };

  const handleSubmit = () =>{
    //  console.log("picture",picture);

      const res = postUserPicture(picture);
      // setPictureMessage(res);
      close();
  }  
  // console.log("pictureMessage",pictureMessage);
  // console.log("pre",userPictureData);

  const imgUrl = `data:image/png;base64, ${userPictureData}`; 

  return (
    <>
      <UserProfileVerticalLine /> 
      <Menu trigger="hover" openDelay={100} closeDelay={300} shadow="md" >
        <Menu.Target>
          <UserProfileButton> 
            {picture ? 
            <UserProfileImg src={picture} alt='' />
            : 
            <UserProfileImg src={userPictureData ? imgUrl : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" } alt='' />
            }
            <OpenPrpfileUsernameIcon>
              <OpenProfileUsername>{userData.username}</OpenProfileUsername>
              <AiFillCaretDown size={22} color="white" />
            </OpenPrpfileUsernameIcon>
          </UserProfileButton>
        </Menu.Target>

        <MenuDropdown>

            <AccountContainer>
              <OpenProfileLink onClick={toggle}>
                <OpenProfileImageContainer>
                {picture ? 
                <UserProfileImg src={picture} alt='' />
                : 
                <UserProfileImg src={userPictureData ? imgUrl : "https://imagedelivery.net/nbNIsDEHJzHp_e3dLUQs9w/8df7e448-aadc-415f-2741-4628ba979100/original" } alt='' />
                }
                  <MdEditIcon size={20} color="white" style={{ cursor: "pointer" }} onClick={toggle} />
                </OpenProfileImageContainer>
              </OpenProfileLink>

              <AccountText>
                <OpenProfileH4>{`${userData.firstname} ${userData.lastname}`}</OpenProfileH4>
                <AccountLabel>{userData.username}</AccountLabel>
                <AccountPoint>
                  <Tooltip text={t("userScore")} position="right" backgroundColor="orange" color="#fff" borderRadius="8px">
                    {/* <RiHandCoinLine size={20} style={{marginRight: "5px"}} /> */}
                    <OpenProfileLink to={`/${startLanguage}/${RemoveTurkishChars({ text: t("userScore") })}`} >
                      <AccountPointWrapper>
                        <CoinIcon src={coin} alt="coin"/>   
                        <CurrencyFormat amount={userPoint || 0} />
                      </AccountPointWrapper>
                    </OpenProfileLink>
                  </Tooltip>
                </AccountPoint>
              </AccountText>

            </AccountContainer>
 
            <div> 

              {/* {menuItems.map((item, index) => (
                <OpenProfileLink key={index} to={item.link} onClick={item.onClick}>
                  <OpenProfileBox>
                    {item.icon}
                    <OpenProfileH4>{item.text}</OpenProfileH4>
                  </OpenProfileBox>

                  <Menu trigger="hover" position="left" openDelay={100} closeDelay={300} shadow="md" >
                    <Menu.Target>
                      <Button>Toggle menu</Button>
                    </Menu.Target>

                    <MenuDropdown>
                      <p>Ercan Yeşil</p>
                    </MenuDropdown>

                  </Menu>

                </OpenProfileLink>
              ))} */}


            {/* {menuItems.map((item, index) => (
              <OpenProfileLink key={index} to={item.link} onClick={item.onClick}>
                <OpenProfileBox>
                  {item.icon}
                  <OpenProfileH4>{item.text}</OpenProfileH4>
                </OpenProfileBox>

                {item.submenu && (
                  <Menu trigger="hover" position="left" openDelay={100} closeDelay={300} shadow="md">
                    <Menu.Target>
                      <Button>Toggle menu</Button>
                    </Menu.Target>

                    <MenuDropdown>
                      {item.submenu.map((subItem, subIndex) => (
                        <OpenProfileLink key={subIndex} to={subItem.link} onClick={subItem.onClick}>
                          <OpenProfileBox>
                            {subItem.icon}
                            <OpenProfileH4>{subItem.text}</OpenProfileH4>
                          </OpenProfileBox>
                        </OpenProfileLink>
                      ))}
                    </MenuDropdown>
                  </Menu>
                )}
              </OpenProfileLink>
            ))} */}

          {menuItems.map((item, index) => (
            <OpenProfileLink
              key={index}
              to={item.link}
              onClick={item.onClick}
            >
              <OpenProfileBox>
                {item.icon}
                <OpenProfileH4>{item.text}</OpenProfileH4>
              </OpenProfileBox>

              {item.submenu && item === menuItems.find((menuItem) => menuItem.text === "help") && (
                <Menu
                  trigger="hover"
                  position="left"
                  openDelay={100}
                  closeDelay={300}
                  shadow="md"
                >
                  <Menu.Target>
                    <Button>Toggle menu</Button>
                  </Menu.Target>

                  <MenuDropdown>
                    {item.submenu.map((subItem, subIndex) => (
                      <OpenProfileLink
                        key={subIndex}
                        to={subItem.link}
                        onClick={subItem.onClick}
                      >
                        <OpenProfileBox>
                          {subItem.icon}
                          <OpenProfileH4>{subItem.text}</OpenProfileH4>
                        </OpenProfileBox>
                      </OpenProfileLink>
                    ))}
                  </MenuDropdown>
                </Menu>
              )}
            </OpenProfileLink>
          ))}

            </div>

        </MenuDropdown>
      </Menu>

      <Dialog opened={opened}  onClose={close} size="xl" radius="md" position={{ top: 100, left: 500 }}>
        <Text size="sm" mb="xs" fw={500}>
          {t("profilePicture")}
        </Text>
        <DialogContainer>
          <Avatar
            width={400}
            height={300}
            onCrop={onCrop}
            onClose={onClose}
            src={src}
          />
          <ModalButtonContainer>
            <ModalCloseButton onClick={ () => {close();}}>{t("close")}</ModalCloseButton>
            <SaveButton onClick={handleSubmit}>{t("save")}</SaveButton>          
          </ModalButtonContainer>

        </DialogContainer>        
      </Dialog>
    </>
  )
}

export default User

export const UserProfile = styled.div`
  margin: 0 15px 0 15px;
  cursor: pointer;
`;

export const UserProfileButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 25px;

  &:hover {
    // background-color: grey;
  }
`;

export const UserProfileImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;
  
export const OpenProfile = styled.div`
  color: #000;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  position: absolute;
  top: 57px;
  right: 70px;
  width: 210px;
  background: var(--bg-navbar);
  border: 1px solid #04545e;
  border-radius: 10px;
  z-index: 1;
`;

export const MenuDropdown = styled(Menu.Dropdown)`
  color: #000;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  background: var(--bg-navbar);
  border: 1px solid #04545e;
  border-radius: 10px;
`;

export const AccountContainer = styled.div`
  display: flex;
  padding: 15px;
`;
export const AccountText = styled.div`
  color: white;
`;
export const AccountLabel = styled.div`
  margin-left: 10px;
`;
export const AccountPoint = styled.div`
  margin-left: 10px;
  margin-top: 15px;
  position: relative;
  cursor: pointer;
`;
export const AccountPointWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OpenProfileAcount = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

export const OpenProfileImageContainer = styled.div`
  position: relative;

`;

export const MdEditIcon = styled(MdEdit)`
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
`;

export const OpenProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
`;

export const OpenProfileBox = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  padding: 10px 15px;
  border: 0;
  /* buradaki değerler iki tane dropdown a göre değişicek*/
  background: var(--bg-navbar);   
  color: white;
  cursor: pointer;
  border-bottom: 1px solid grey;
  border-radius: 10px;

  &:hover {
    color: orange;
  }
`;

export const OpenProfileH4 = styled.h4`
  margin: 0;
  margin-left: 10px;
  font-weight: 500;
`;

export const OpenPrpfileUsernameIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OpenProfileUsername = styled.p`
  margin: 10px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(192, 192, 192);
`;

export const UserProfileVerticalLine = styled.div`
  border-left: 2px solid rgb(192, 192, 192);
  height: 35px;
`;

export const OpenProfileLink = styled(Link)`
  text-decoration: none;
  color: white;

  &:hover {
    color: orange;
  }
`;

// Stillemeler
export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  background-color: #f1f1f1; /* Değişen kısım */
`;

export const DropdownHeader = styled.button`
  background-color: #f1f1f1;
  color: #333;
  padding: 10px;
  border: none;
  cursor: pointer;
`;

export const DropdownContent = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: absolute;
  background-color: #fff; /* Değişen kısım */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;
`;

export const SubDropdownContainer = styled.div`
  margin-top: 10px;
`;

export const SubDropdownHeader = styled.button`
  background-color: var(--bg-navbar);
  color: white;
  border: none;
  cursor: pointer;
  border-bottom: 1px solid grey;
  padding: 10px 15px;
`;

export const SubDropdownContent = styled.div`
  display: ${({ open }) => (open ? "block" : "none")};
  position: absolute;
  background-color: #fff; /* Değişen kısım */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 5px;
`;




// Sonradan yaptığım dropdown
export const Dropdown = styled.div`
  position: relative;
`;

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const DropdownMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  min-width: 205px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform-origin: top right;
  transform: scaleY(${({ isOpen }) => (isOpen ? "1" : "0")});
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

export const MenuItem = styled.a`
  display: block;
  color: #333;
  text-decoration: none;
  padding: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const AA = styled.div`
  background-color. red;
`;

export const ImageEditingOptions = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--bg-navbar);
  border: 1px solid #04545e;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  padding: 10px;
`;

export const OptionButton = styled.button`
  display: block;
  width: 100%;
  background-color: transparent;
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #04545e;
  }
`;

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: auto;
  margin-top: 40px;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 15px;
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  color: #333;
  padding: 10px 15px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const SaveButton = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
`;

export const CoinIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 5px;
`;